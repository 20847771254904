<template>
  <div>
    <div
        :class="['usernameBox',{'focus':isFocus},{'error':errorMessage != null}]"
        ref="mycolor"
        v-bind:style="[{'height': height},{[{'background':'red'}]:isFocus}]"
    >
      <div class="icon">
        <i :class="['iconfont',icon]"></i>
      </div>
      <input
          class="input"
          :type="type"
          v-model="inputValue_"
          @input="inputChange"
          @focus="inputFocus"
          @focusout="inputFocusout"
          :placeholder="placeholder"
      >
      </input>
    </div>
    <div style="width: 100%;height: 20px;text-align: left;font-size: 14px;color: #DD5C5C;margin-top: 2px">
      <span v-if="errorMessage != null">&nbsp;{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextBox",
  props: {
    inputValue: {type: String, required: false, default: ''},
    type: {type: String, required: false, default: ''},
    icon: {type: String, required: false, default: ''},
    errorMessage: {type: String, required: false, default: null},
    placeholder: {type: String, required: false, default: ''},
    height: {type: String, required: false, default: '46px'},
    color: {type: String, required: false, default: '#008ADF'},
  },
  data() {
    return {
      isFocus: false,
      inputValue_:this.inputValue,
    }
  },
  methods: {
    inputFocus: function () {
      this.isFocus = true
    },
    inputFocusout: function () {
      this.isFocus = false
    },
    inputChange: function () {
      this.$emit('update:errorMessage', null)
      this.$emit('update:inputValue', this.inputValue_)
    }
  },
  mounted() {
    this.$refs.mycolor.style.setProperty("--bcColor",this.color);
  }
}
</script>

<style scoped lang="scss">
//水印样式
::-webkit-input-placeholder {
  color: #C1C7D4;
  font-size: 14px;
}

//默认样式
.usernameBox {
  width: 100%;
  border: #DCE1E6 solid 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  .icon {
    width: 45px;
    height: 100%;
    background: #F6F9FC;
    border-right: #DCE1E6 solid 1px;
    border-radius: 2px 0px 0px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #93A2BB;
  }
  .input {
    background: none;
    outline: none;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    width: 100%;
  }
}
//焦点样式
.focus {
  --bcColor: #0FBD9F;
  border-color: var(--bcColor);
  background: #f5f5f5;
  box-shadow: 0px 0px 3px 1px rgba(36, 97, 246, 0.24);
  .icon {
    border-color: var(--bcColor);
    background: var(--bcColor);
    color: white;
  }
}
//错误样式
.error {
  border-color: #DD5C5C;
  background: #FCF6F7;
  box-shadow: 0px 0px 3px 1px rgba(221, 92, 92, 0.36);

  .icon {
    border-color: #DD5C5C;
    background: #DD5C5C;
    color: white;
  }
}
</style>