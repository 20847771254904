<template>
  <div class="root" style="background: #F5F5F5">

    <div class="content">
      <h3>选择重置密码的方式</h3>
      <div style="display: flex;flex-direction: row">
        <div class="card" @click="goToEmail_reset">
          通过邮箱找回
        </div>
        <div class="card" @click="goToMobile_reset">
          短信验证
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "JumpFindPwd",
  methods:{
    goToMobile_reset:function (){
      this.$router.push('/mobile_reset');
    },
    goToEmail_reset:function (){
      this.$router.push('/email_reset');
    }
  }
}
</script>

<style scoped>
.root{
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
}
.content{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card{
  width: 200px;
  height: 100px;
  background: #F6F6F6;
  margin: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #366CB3 2px;
  cursor: pointer;
}
.card:hover{
  background: #366CB3;
  color: #ffffff;
}
</style>