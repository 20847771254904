import axios from "axios";
import api from "@/api/api";

const email_reset = {
    sendEmailCode:function (username,password,dx_token) {
        let fromData = new FormData()
        fromData.append('username', username)
        fromData.append('password', password)
        fromData.append('dx_token',dx_token)
        return  axios.post(api.baseUrl()+'/api/web/findPwd/email/sendEmailCode', fromData);
    },
    submit:function (emailCode) {
        let fromData = new FormData()
        fromData.append('emailCode',emailCode)
        return  axios.post(api.baseUrl()+'/api/web/findPwd/email/submit', fromData);
    },
}
export default email_reset