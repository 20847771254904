import axios from "axios";
import api from "@/api/api";

const mobile_register = {
    sendPhoneCode:function (username,phone,password,dx_token) {
        let fromData = new FormData()
        fromData.append('username', username)
        fromData.append('phone', phone)
        fromData.append('password', password)
        fromData.append('dx_token',dx_token)
        return  axios.post(api.baseUrl()+'/api/web/register/mobile/sendPhoneCode', fromData);
    },
    submit:function (phoneCode) {
        let fromData = new FormData()
        fromData.append('code',phoneCode)
        return  axios.post(api.baseUrl()+'/api/web/register/mobile/submit', fromData);
    },
}
export default mobile_register