<template>
  <div class="home">
    <router-link to="/email_register">账户注册 (通过邮箱)</router-link>
    <hr>
    <router-link to="/mobile_register">账户注册 (通过手机)</router-link>
    <hr>
    <router-link to="/mobile_reset">密码重置 (手机号码方式)</router-link>
    <hr>
    <router-link to="/email_reset">密码重置 (邮箱方式)</router-link>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
