import mobile_reset from "./public/mobile_reset";
import email_register from "./public/email_register";
import email_reset from "./public/email_reset";
import mobile_register from "./public/mobile_register";
const api = {
    mobile_reset,
    email_register,
    email_reset,
    mobile_register,
    baseUrl:function (){
        return ""
    }
}
export default api;