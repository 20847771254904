const strUtility = {
    isTeShuFuHao:function (str){
        const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if (pattern.test(str)){
            return true;
        }
        return false;
    },
    isEmail:function(email) {
        const pattern = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        const strEmail = pattern.test(email);
        if(strEmail){
            return true
        }else{
            return false
        }
    },
    //是否包含中文
    isChinese:function (str){
        const patrn = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
        if(!patrn.exec(str)){
            return false;
        }else{
            return true;
        }
    },
    //正则表达式验证是否为手机号码
    isMobile: function(s){
        let reg =/^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        return reg.test(s);
    },
}
export default strUtility