<template>
  <div class="main-root">
    <div class="main-content">
      <div class="content-box">
        <div>
          <h2>密码重置<span style="font-size: 12px;color: #2c3e50"> &nbsp;手机号方式</span></h2>
        </div>
        <text-box :input-value.sync="from.username.value" :error-message.sync="from.username.error" height="43px" color="#409EFF" placeholder="账号 / 手机号" icon="icon-yonghuming2" ></text-box>
        <text-box :input-value.sync="from.password.value" :error-message.sync="from.password.error" type="password" height="43px"  color="#409EFF" placeholder="请设置新密码" icon="icon-mima1" ></text-box>
        <div style="display: flex;align-items: center;justify-content: center">
          <text-box  :input-value.sync="from.phoneCode.value" style="margin-right: 15px" :error-message.sync="from.phoneCode.error" height="43px" color="#409EFF" placeholder="短信验证码" icon="icon-shebeishouquan" ></text-box>
          <el-button style="margin-bottom: 22px;height: 43px" plain :disabled="button_sendMessage.isDisabled"  @click="sendPhoneCode" :loading="button_sendMessage.isLoading">
            {{button_sendMessage.title}}
          </el-button>
        </div>
        <div ref="demo" style="margin-bottom: 10px;"></div>
        <el-button type="primary" style="width: 100%" @click="submit">提交修改</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import TextBox from "@/components/ControlBox/TextBox";
import api from "@/api/api";

export default {
  name: "mobile_reset",
  components: {TextBox},
  data(){
    return{
      dx:null,
      dx_token:null,

      from:{
        username:{value:'',error:null},
        password:{value:'',error:null},
        phoneCode:{value:'',error:null},
      },
      button_sendMessage:{
        isLoading:false,
        title:'发送验证码',
        isDisabled:false
      }
    };
  },
  methods:{
    sendPhoneCode:function (){
      if(!this.checkValue())
        return
      if(this.dx_token === null){
        this.dx.reload();
        this.dx.show();
        return;
      }
      api.mobile_reset.sendPhoneCode(this.from.username.value,this.from.password.value,this.dx_token).then((re)=>{
        let data = re.data
        this.dx_token = null
        this.$alert(data.msg, '提示', {confirmButtonText: '确定', callback: action => {}});
        if(data.state === 1){
          //- 重新计算发信时间
          let me = this.button_sendMessage;
          me.isDisabled = true;
          me.time = 60;
          let interval = window.setInterval(function() {
            me.title = '(' + me.time + ')秒重发';
            --me.time;
            if(me.time <= 0) {
              me.title = "重新发送";
              me.time = 60;
              me.isDisabled = false;
              window.clearInterval(interval);
            }
          }, 1000);
          //-
        }
      })
    },
    submit:function (){
      if(!this.checkValue())
        return
      let from = this.from;
      if(from.username.error != null || from.password.error != null || from.phoneCode.error != null){
        return;
      }
      if(this.from.phoneCode.value.length === 0){
        this.from.phoneCode.error = "请输入验证码"
        return
      }
      api.mobile_reset.submit(this.from.phoneCode.value).then((re)=>{
        let data = re.data
        this.$alert(data.msg, '提示', {confirmButtonText: '确定', callback: action => {}});
      })
    },
    checkValue:function (){
      let result = true;
      if(this.from.username.value.length  === 0){
        this.from.username.error = "请输入账号 或 手机号 ";
        result = false
      }
      if(this.from.password.value.length  === 0){
        this.from.password.error = "请输入密码";
        result = false
      }
      return result
    },
  },
  mounted() {
    this.dx = _dx.Captcha(this.$refs.demo, {
      appId: '9cb7321062d32942b5f6137e8c060c66', //appId，在控制台中“应用管理”或“应用配置”模块获取
      style:'popup',
      //style:'oneclick',
      width:'290',
      _noLogo:true,
      logoLink:false,
      // 下面开始自定义样式
      customStyle: {
        bgColor: '#1472cc' // <-- 自定义控件背景色
      },
      // 下面开始自定义语言
      customLanguage: {
        init_inform: '拖动一下', // <-- 初始化时的提示文案
        slide_inform: '向右向右', // <-- 滑块中的提示文案
        pass_by_server:'验证成功'
      },
      success: token => {
        this.dx_token = token;
        this.dx.hide();
        this.sendPhoneCode();
      }
    });
  }
}
</script>
<style scoped lang="scss">

.dx_captcha_oneclick_bar{
  background: red;
}
.main-root{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 15px;
  background: #F6F6F6;
}
.main-content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box{
  width: 280px;
}
</style>