import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import email_register from '../views/public/email_register.vue'
import email_reset from '../views/public/email_reset.vue'
import mobile_reset from '../views/public/mobile_reset.vue'
import mobile_register from '../views/public/mobile_register.vue'
import JumpFindPwd from '../views/JumpFindPwd.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/email_register',
    name: 'email_register',
    component: email_register
  },
  {
    path: '/email_reset',
    name: 'email_reset',
    component: email_reset
  },
  {
    path: '/mobile_reset',
    name: 'mobile_reset',
    component: mobile_reset
  },
  {
    path: '/mobile_register',
    name: 'mobile_register',
    component: mobile_register
  },
  {
    path: '/JumpFindPwd',
    name: 'JumpFindPwd',
    component: JumpFindPwd
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      //return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
